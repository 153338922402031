@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.flicker{
    animation: flicker-shadow 5s infinite;
}
@keyframes flicker-shadow {
    0%, 100% {
        filter: drop-shadow(4px 4px 8px #b59004);
    }
    20% {
        filter: drop-shadow(6px 6px 12px #b58f04de);
    }
    40% {
        filter: drop-shadow(2px 2px 5px #b58f04b8);
    }
    60% {
        filter: drop-shadow(5px 5px 10px #b58f0474);
    }
    80% {
        filter: drop-shadow(3px 3px 7px #b58f04d0);
    }
}

.sidebar::-webkit-scrollbar{
    display: none;
}

body{
    background-color: #0D0D0D;
    font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 908px) {
    .adjust-top{
        bottom: 0px;
    }
}
@media screen and (min-width: 1680px) {
    .about {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .home-text > p {
        font-size: 19px !important;
        line-height: 25px !important;
    }
    .adjust-top{
        bottom: 5%;
    }
}
@media screen and (min-width: 1920px) {
    .about {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .home-text > p {
        font-size: 22px !important;
        line-height: 30px !important;
    }
    .adjust-top{
        bottom: 10%;
    }
}